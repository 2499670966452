@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: "Vella Sans";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../public/assets/fonts/VelaSans-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/VelaSans-Regular.woff") format("woff"),
    url("../public/assets/fonts/VelaSans-Regular.ttf") format("truetype");
} */

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../public/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("../public/assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url("../public/assets/fonts/Roboto-Italic.ttf");
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.calendar .react-calendar {
  border: none;
}

/* Swiper JS style start */
.swiper-pagination-bullet-active {
  background-color: #ee4329 !important;
}

.simplebar-scrollbar:before {
  background: #ee4329 !important;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.shadow-inner {
  box-shadow: inset 0px 0px 11px -3px rgb(34 126 246 / 31%);
}
